import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Seo from '../components/Seo';
import type { GatsbyPageProps } from '../types';

const useStyles = makeStyles<Theme>(({}) => ({
  root: {
    height: '100%',
  },
}));

const NotFoundPage: React.FC<GatsbyPageProps> = () => {
  const classes = useStyles();

  return (
    <>
      <Seo description="Error 404. Page not found." title="Error 404" />
      <Grid
        alignItems="center"
        className={classes.root}
        container
        direction="column"
        justify="center"
      >
        <Typography variant="h1">Error 404.</Typography>
        <Typography variant="body1">Page not found.</Typography>
      </Grid>
    </>
  );
};

export default NotFoundPage;
